<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：资料主页面
	开始时间：2021-01-14
	开发人员：刘巍骏
	最后修改：2021-01-14
	备注说明：如需修改请联系开发人员
-->
<template>
  <!-- 资料总框 -->
  <div class="datumIndex defined_Dialog">
    <router-view></router-view>
  </div>
</template>

<script>
  export default{
    name:'datumIndex',
    data(){
      return{

      }
    },
  }
</script>

<style lang="less">
  .datumIndex{
    height: calc(100% - 2px);
    display: flex;
    margin-top: 2px;
    // border: 1px solid black;
  }
</style>

<!-- 导入单据公共样式 -->
<style lang="less" src="@/less/Comm/comm_billing.less"></style>
